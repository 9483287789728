import _ from "lodash";
import {
  createTheme,
  getContrastRatio,
  darken,
  lighten,
} from "@mui/material/styles";
import { THEMES } from "./constants";
import typography from "./typography";

const baseOptions = {
  typography,
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: "1.6 !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          textTransform: "inherit !important",
          minWidth: 40,
          minHeight: 40,
        },
        startIcon: {
          marginInlineEnd: 4,
          marginRight: 0,
          width: 24,
          height: 24,
        },
        endIcon: {
          marginInlineStart: 4,
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          padding: "0 8px",
        },
        avatar: {
          margin: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Readex Regular",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            "&:hover": {
              border: "1px solid #3E8DFB !important",
            },
          },
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    palette: {
      type: "light",
      action: {
        // active: "rgba(255, 255, 255, 0.54)",
        // hover: "rgba(0, 0, 0, 0.12)",
        // selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(0, 0, 0, 0.35)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        // focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#eeeeee",
        dark: "#f4f6f8",
        paper: "#fff",
      },
      primary: {
        main: "#3E8DFB",
        light: "#D4E1FA",
        dark: "#214EB1",
        medium: "#6CA4FC",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#2B3270",
        light: "#DFE2F8",
        dark: "#17093A",
        medium: "#8891DD",
        contrastText: "#FFFFFF",
      },
      error: {
        main: "#DE1860",
        light: "#FDE1E7",
        dark: "#931E5C",
        medium: "#FC4C8F",
        contrastText: "#FFFFFF",
      },
      warning: {
        main: "#E27964",
        light: "#FEEBD7",
        dark: "#CA5649",
        medium: "#FCC0A9",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#83736F",
        light: "#EDE0CF",
        dark: "#5A493C",
        medium: "#BEA898",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#25D9C5",
        light: "#CCF2ED",
        dark: "#0F7373",
        medium: "#8CEBE1",
        contrastText: "#FFFFFF",
      },
      black: {
        b1: "#0000000A",
        b2: "#0000001F",
        b3: "#00000061",
        b4: "#00000061",
        b5: "#000000BC",
        b6: "#000000DE",
      },
      white: {
        w1: "#FFFFFF1F",
        w2: "#FFFFFF61",
        w3: "#FFFFFF99",
        w4: "#FFFFFFBD",
        w5: "#FFFFFFDE",
        w6: "#FFFFFF",
      },
    },
  },
];

export const createAppTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
  if (!themeOptions) {
    // console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  const mainColor = config?.client?.primary?.main;
  const contrastText = config?.client?.primary?.contrastText;
  let primary = {
    main: mainColor && mainColor,
    medium: mainColor && lighten(mainColor, 0.4),
    light: mainColor && lighten(mainColor, 0.85),
    dark: mainColor && darken(mainColor, 0.4),
    contrastText: contrastText
      ? contrastText
      : mainColor && getContrastRatio(mainColor, "#fff") > 4.5
      ? "#fff"
      : "#111",
  };

  let theme = createTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction },
      config.client.clientId !== "zinki" && { palette: { primary: primary } },
      { logo: config.client.logo },
      { whiteLogo: config.client.whiteLogo },
      { clientId: config.client.clientId }
    )
  );

  return theme;
};
