import React, { useState } from "react";
import { Dialog, DialogTitle, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
}));

const AppTutorialVideo = ({ open, onClose }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { user, setOnboardingData } = useAuth();
  const [play, setPlay] = useState(false);

  const closeAppTutorialDialog = () => {
    onClose();
    if (!user.data.onboardingData.tutorialOnboarding.isChecked) {
      setOnboardingData("tutorial", "docId", true);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={play}
        open={open}
        onClose={closeAppTutorialDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: play
            ? "app-tutorial-video-dialog-paper"
            : "app-tutorial-video-dialog-paper-first-render",
        }}
        scroll={play ? "paper" : "body"}
        classes={{
          root: !play && classes.root,
        }}
      >
        <DialogTitle
          id="app-tutorial-video-dialog-title"
          className={play && "app-tutorial-video-dialog-title"}
        >
          <Typography
            variant="body2"
            color="var(--secondary-text-color)"
            className={!play && "app-tutorial-video-dialog-title-typography"}
          >
            {t("MessageAccompaningTheVideo")}
          </Typography>
          <IconButton
            id="button-close-app-tutorial-video"
            onClick={closeAppTutorialDialog}
            className="app-tutorial-video-dialog-close-icon-button"
            color="secondary"
          >
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </DialogTitle>
        <ReactPlayer
          playing={play}
          url="https://youtu.be/_tbYA3w-myk"
          onStart={() => setPlay(true)}
          width="100%"
          height="100%"
          className="app-tutorial-video-dialogreact-player"
          controls={true}
        />
      </Dialog>
    </div>
  );
};
export default AppTutorialVideo;
