import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Button, Typography, Link, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiFolderHome } from "@mdi/js";
import useAuth from "../../../hooks/useAuth";
import useData from "../../../hooks/useData";
import SelectLanguage from "../../common/SelectLanguage";
import SearchPage from "./SearchPage";
import Profile from "./Profile";
import "../../../assets/styles/layout.css";

const useStyles = makeStyles((theme) => ({
  headerDemoVersion: {
    borderRadius: 4,
    padding: "2px 4px",
    display: theme.clientId !== "zinki" ? "none !important" : "flex",
    backgroundColor: "var(--error-light-color)",
    color: "var(--error-main-color) !important",
    fontWeight: "600 !important",
    fontSize: "12px !important",
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${theme.logo})`,
  backgroundRepeat: "no-repeat",
  width: theme.clientId !== "zinki" ? 140 : 95,
  height: 40,
  backgroundSize: "contain",
  backgroundPositionX: "left",
}));

const Header = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getFolders, goToWorkspace } = useData();

  const handleNavigateToMyFolders = async () => {
    await goToWorkspace(true);
    if (window.location.href.includes("app=sebr")) {
      await navigate("/workspace?app=sebr");
    } else {
      await navigate("/");
    }
    await getFolders(1, 25, "", "myFolders");
  };

  const reducer = (accumulator, curr) => accumulator + curr;

  return (
    <>
      <AppBar position="static" elevation={1} className="header-appBar">
        <Toolbar className="header-toolbar">
          <Button
            id="logoButton-workspace"
            onClick={handleNavigateToMyFolders}
            className="header-logo-button"
          >
            <Logo />
            <Typography variant="body2" className={classes.headerDemoVersion}>
              {`${t("demoVersion")} - BETA`}
            </Typography>
          </Button>
          <div className="header-my-workspace-and-my-profile">
            <SearchPage />
            <Button
              id="button-workspace"
              onClick={handleNavigateToMyFolders}
              color="primary"
              startIcon={<Icon path={mdiFolderHome} size={1} />}
            >
              {t("workspace")}
            </Button>
            <SelectLanguage />
            <Profile />
          </div>
        </Toolbar>
      </AppBar>
      {user?.data?.offerName === "free" && (
        <div className="header-upgrade-alert">
          <Typography
            variant="body2"
            className="header-upgrade-alert-typography"
          >
            {t("yourAccountIsFreeYouCan")}
          </Typography>
          <Link
            id="link-upgrade-topbar"
            variant="body2"
            color="primary"
            onClick={() => {
              window.location.href.includes("app=sebr")
                ? navigate(`/price-simulator?app=sebr`)
                : navigate(`/price-simulator`);
            }}
            className="header-upgrade-alert-link"
          >
            {t("upgrade")}
          </Link>
        </div>
      )}
      {user?.data?.offerName === "premium" &&
        user?.data?.totalQuotaDetails?.map((el) => el.quota).reduce(reducer) ===
          0 && (
          <div className="header-upgrade-alert">
            <Typography
              variant="body2"
              className="header-upgrade-alert-typography"
            >
              {t("youCantTranscribePagesAnymore")}
            </Typography>
            <Link
              id="link-upgrade-topbar"
              variant="body2"
              color="primary"
              onClick={() => {
                navigate(`/price-simulator`);
              }}
              className="header-upgrade-alert-link"
            >
              {t("upgrade")}
            </Link>
          </div>
        )}
    </>
  );
};
export default Header;
