import React, { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import config from "../utils/settings";
import useData from "../hooks/useData";

const initialDataState = {
  editSegmentation: false,
  displayPolygons: false,
  displayRegions: false,
  displayBaselines: false,
  editBaselines: false,
  addBaselines: false,
  selectedBaseline: [],
  selectedAnchorPoints: [],
  baselineIndex: null,
  imageBase64: null,
  polygons: [],
  regions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_EDIT_SEGMENTATION": {
      return {
        ...state,
        editSegmentation: action.payload,
      };
    }
    case "SET_DISPLAY_POLYGONS": {
      return {
        ...state,
        displayPolygons: action.payload,
      };
    }
    case "SET_DISPLAY_REGIONS": {
      return {
        ...state,
        displayRegions: action.payload,
      };
    }
    case "SET_DISPLAY_BASELINES": {
      return {
        ...state,
        displayBaselines: action.payload,
      };
    }
    case "SET_EDIT_BASELINES": {
      return {
        ...state,
        editBaselines: action.payload,
      };
    }
    case "SET_ADD_BASELINES": {
      return {
        ...state,
        addBaselines: action.payload,
      };
    }
    case "SET_SELECTED_BASELINE": {
      return {
        ...state,
        selectedBaseline: action.payload,
      };
    }
    case "SET_BASELINES": {
      return {
        ...state,
        baselines: action.payload,
      };
    }
    case "SET_POLYGONS": {
      return {
        ...state,
        polygons: action.payload,
      };
    }
    case "SET_REGIONS": {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case "SELECT_ANCHOR_POINTS": {
      return {
        ...state,
        selectedAnchorPoints: action.payload,
      };
    }
    case "SET_BASELINE_INDEX": {
      return {
        ...state,
        baselineIndex: action.payload,
      };
    }
    case "SET_IMAGE_BASE64": {
      return {
        ...state,
        imageBase64: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
const PageContext = createContext({
  ...initialDataState,
  setEditSegmentation: () => Promise.resolve(),
  setDisplayPolygons: () => Promise.resolve(),
  setDisplayRegions: () => Promise.resolve(),
  setDisplayBaselines: () => Promise.resolve(),
  setEditBaselines: () => Promise.resolve(),
  setAddBaselines: () => Promise.resolve(),
  setSelectedBaseline: () => Promise.resolve(),
  setBaselines: () => Promise.resolve(),
  selectAnchorPoints: () => Promise.resolve(),
  deleteSelectedAnchorPoints: () => Promise.resolve(),
  updateSelectedBaseline: () => Promise.resolve(),
  setImageBase64ToEditSegmentation: () => Promise.resolve(),
  setPolygons: () => Promise.resolve(),
  setRegions: () => Promise.resolve(),
  addNewBaseline: () => Promise.resolve(),
  deleteBaseline: () => Promise.resolve(),
});

export const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);
  const { getLines, setNewText, textLines, setTextLines } = useData();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getSnackbar = (message, status, description) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
        {description ? (
          <>
            <br />
            {t(description)}
          </>
        ) : null}
      </Typography>,
      {
        variant: status,
        autoHideDuration:
          status === "error" ||
          message === "successUploadFile" ||
          message === "successTranscriptionPages"
            ? null
            : 5000,
      }
    );
  };

  const setEditSegmentation = (booleen) => {
    dispatch({
      type: "SET_EDIT_SEGMENTATION",
      payload: booleen,
    });
  };
  const setDisplayPolygons = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: booleen,
    });
    localStorage.setItem("displayPolygons", JSON.stringify(booleen));
  };
  const setDisplayRegions = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: booleen,
    });
    localStorage.setItem("displayRegions", JSON.stringify(booleen));
  };
  const setDisplayBaselines = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: booleen,
    });
    localStorage.setItem("displayBaselines", JSON.stringify(booleen));
  };
  const setEditBaselines = (booleen) => {
    dispatch({
      type: "SET_EDIT_BASELINES",
      payload: booleen,
    });
  };
  const setAddBaselines = (booleen) => {
    dispatch({
      type: "SET_ADD_BASELINES",
      payload: booleen,
    });
  };
  const setSelectedBaseline = (baseline, indexOfBaseline) => {
    dispatch({
      type: "SET_SELECTED_BASELINE",
      payload: baseline,
    });
    dispatch({
      type: "SET_BASELINE_INDEX",
      payload: indexOfBaseline,
    });
  };
  const setBaselines = (array) => {
    dispatch({
      type: "SET_BASELINES",
      payload: array,
    });
  };
  const setPolygons = (array) => {
    dispatch({
      type: "SET_POLYGONS",
      payload: array,
    });
  };
  const setRegions = (array) => {
    dispatch({
      type: "SET_REGIONS",
      payload: array,
    });
  };

  const selectAnchorPoints = (array) => {
    dispatch({
      type: "SELECT_ANCHOR_POINTS",
      payload: array,
    });
  };

  const deleteSelectedAnchorPoints = (
    folderId,
    documentId,
    imageId,
    lineId,
    imageBase64,
    updatedBaselines,
    baselineIndex,
    selectedAnchorPoints,
    action
  ) => {
    const sortedIndices = selectedAnchorPoints?.slice().sort((a, b) => b - a);
    if (
      selectedAnchorPoints.length &&
      updatedBaselines[baselineIndex].length !==
        selectedAnchorPoints?.length + 1 &&
      action !== "deleteBaseline"
    ) {
      sortedIndices.forEach((pointKey) => {
        updatedBaselines[baselineIndex].splice(pointKey, 1);
      });
      updateSelectedBaseline(
        folderId,
        documentId,
        imageId,
        lineId,
        imageBase64,
        updatedBaselines[baselineIndex],
        baselineIndex
      );
    } else {
      setSelectedBaseline([], null);
      updatedBaselines[baselineIndex] = [];
      deleteBaseline(folderId, documentId, imageId, lineId);
    }
    setBaselines(updatedBaselines);
    selectAnchorPoints([]);
    // setSelectedBaseline([], null);
  };
  const updateSelectedBaseline = async (
    folderId,
    documentId,
    imageId,
    lineId,
    imageBase64,
    newBaseline,
    order
  ) => {
    const newBaselineOfArrays = newBaseline.map(({ x, y }) => [x, y]);
    let body = {
      image: imageBase64,
      newBaseline: newBaselineOfArrays,
      order: order,
    };

    const setItemInPlace = (data) => {
      const newPolygons = state.polygons?.map((elt, index) =>
        index === data.index ? data.mask : elt
      );
      textLines[data.line.index].polygon = data.mask;
      const newBaselines = state.baselines?.map((elt, index) =>
        index === data.index ? data.line.baseline : elt
      );
      dispatch({
        type: "SET_BASELINES",
        payload: newBaselines,
      });
      dispatch({
        type: "SET_POLYGONS",
        payload: newPolygons,
      });
    };

    await axios
      .put(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`,
        body
      )
      .then(async (response) => {
        getLines(folderId, documentId, imageId, "getLinesAfterEdirBaseline");
        setItemInPlace(response.data.data);
        setNewText([
          {
            index: 0,
            lineId: response.data.data.line._id,
            newVersion:
              response.data.data.line.history[
                response.data.data.line.history.length - 1
              ].text,
            polygon: response.data.data.mask,
          },
        ]);
      })
      .catch(async (err) => {
        getSnackbar("errorEditBaseLine", "error");
      });
  };

  const setImageBase64ToEditSegmentation = (string) => {
    dispatch({
      type: "SET_IMAGE_BASE64",
      payload: string,
    });
  };

  const addNewBaseline = async (
    folderId,
    documentId,
    imageId,
    imageBase64,
    newBaseline,
    updatedBaselines
  ) => {
    const newBaselineOfArrays = newBaseline.map(({ x, y }) => [x, y]);

    let body = {
      image: imageBase64,
      newBaseline: newBaselineOfArrays,
    };
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${imageId}`,
        body
      ).then(async (response) => {
        getLines(folderId, documentId, imageId, "getLinesAfterEdirBaseline");
        return
      })
      .catch(async (err) => {
        const newArray = updatedBaselines.slice(0, -1);
        setBaselines(newArray);
        getSnackbar("errorAddNewBaseLine", "error");
      });
  };

  const deleteBaseline = async (folderId, documentId, imageId, lineId) => {
    await axios
      .delete(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`
      )
      .then(async (response) => {
        getLines(folderId, documentId, imageId, "getLinesAfterEdirBaseline");
      })
      .catch(async (err) => {
        getSnackbar("errorDeleteBaseLine", "error");
      });
  };

  useEffect(() => {
    const displayPolygons = JSON.parse(localStorage.getItem("displayPolygons"));
    const displayRegions = JSON.parse(localStorage.getItem("displayRegions"));
    const displayBaselines = JSON.parse(
      localStorage.getItem("displayBaselines")
    );

    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: displayPolygons,
    });
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: displayRegions,
    });
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: displayBaselines,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <PageContext.Provider
      value={{
        ...state,
        setEditSegmentation,
        setDisplayPolygons,
        setDisplayRegions,
        setDisplayBaselines,
        setEditBaselines,
        setAddBaselines,
        setSelectedBaseline,
        setBaselines,
        selectAnchorPoints,
        deleteSelectedAnchorPoints,
        updateSelectedBaseline,
        setImageBase64ToEditSegmentation,
        setPolygons,
        setRegions,
        addNewBaseline,
        deleteBaseline,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
