import React, { createContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import axios from "axios";
import useData from "../hooks/useData";
import useSettings from "../hooks/useSettings";
import config from "../utils/settings";

const initialSebrState = {
  currentAction: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_ACTION": {
      return {
        ...state,
        currentAction: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const SebrContext = createContext({
  ...initialSebrState,
  setCurrentAction: () => Promise.resolve(),
  publishFile: () => Promise.resolve(),
  unPublishFile: () => Promise.resolve(),
});

export const SebrProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialSebrState);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getFolderByDocumentId, getSingleImageByDocumentIdAndImageId } =
    useData();
  const { settings } = useSettings();

  const getSnackbar = (message, status, description) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
        {description ? (
          <>
            <br />
            {t(description)}
          </>
        ) : null}
      </Typography>,
      {
        variant: status,
        autoHideDuration: status === "error" ? null : 8000,
      }
    );
  };

  const setCurrentAction = (obj) => {
    dispatch({
      type: "SET_CURRENT_ACTION",
      payload: obj,
    });
  };
  const publishFile = async (documentId, imageId, page, onClose, name) => {
    if (page !== "editorView") {
      getSnackbar(
        `${t("publishInProgress")} ${t("theDocument")} ${name}`,
        "success",
        t("thisProcessMayTakeAwhile")
      );
    }
    if (page === "editorView") {
      setCurrentAction({ imageId: imageId, currentAction: "publishPage" });
    } else {
      setCurrentAction({
        documentId: documentId,
        currentAction: "publishDocument",
      });
    }
    onClose();
    await axios
      .post(
        page === "editorView"
          ? `${config.REACT_APP_BASE_URL}/document/${documentId}/image/${imageId}/publish`
          : `${config.REACT_APP_BASE_URL}/document/${documentId}/publish`
      )
      .then(async (response) => {
        if (settings.language === "ar") {
          getSnackbar(
            page === "editorView"
              ? `${t("successPublishPage")} ${t("thePage")} ${name} ${t(
                  "onSearchPlatform"
                )}`
              : `${t("successPublishDocument")} ${t("theDocument")} ${name} ${t(
                  "onSearchPlatform"
                )}`,
            "success"
          );
        } else {
          getSnackbar(
            page === "editorView"
              ? `${t("thePage")} ${name} ${t("successPublishPage")} ${t(
                  "onSearchPlatform"
                )}`
              : `${t("theDocument")} ${name} ${t("successPublishDocument")} ${t(
                  "onSearchPlatform"
                )}`,
            "success"
          );
        }
        setCurrentAction(null);
        if (page === "editorView") {
          getSingleImageByDocumentIdAndImageId(
            documentId,
            imageId,
            "afterPublishOrHidePageOnSebr"
          );
        }
        getFolderByDocumentId(documentId, "afterPublishOrHideDocOnSebr");
      })
      .catch(async (err) => {
        setCurrentAction(null);
        if (
          err?.response?.data?.data?.includes(
            "already published and up to date!"
          )
        ) {
          getSnackbar(
            page === "editorView"
              ? `${t("thePage")} ${name} ${t("errorPageAlreadyPublished")}`
              : `${t("theDocument")} ${name} ${t(
                  "errorDocumentAlreadyPublished"
                )}`,
            "error"
          );
        } else {
          getSnackbar(
            page === "editorView"
              ? `${t("errorPublishPage")} ${name}, ${t("pleaseTryAgain")}`
              : `${t("errorPublishDocument")} ${name}, ${t("pleaseTryAgain")}`,
            "error"
          );
        }
      });
  };

  const unPublishFile = async (documentId, imageId, page, onClose, name) => {
    if (page !== "editorView") {
      getSnackbar(
        `${t("unPublishInProgress")} ${t("theDocument")} ${name}`,
        "success",
        t("thisProcessMayTakeAwhile")
      );
    }
    if (page === "editorView") {
      setCurrentAction({ imageId: imageId, currentAction: "hidePage" });
    } else {
      setCurrentAction({
        documentId: documentId,
        currentAction: "hideDocument",
      });
    }
    onClose();
    await axios
      .put(
        page === "editorView"
          ? `${config.REACT_APP_BASE_URL}/document/${documentId}/image/${imageId}/unpublish`
          : `${config.REACT_APP_BASE_URL}/document/${documentId}/unpublish`
      )
      .then(async (response) => {
        if (settings.language === "ar") {
          getSnackbar(
            page === "editorView"
              ? `${t("successUnPublishPage")} ${name} ${t(
                  "fromSearchPlatform"
                )}`
              : `${t("successUnPublishDocument")} ${name} ${t(
                  "fromSearchPlatform"
                )}`,
            "success"
          );
        } else {
          getSnackbar(
            page === "editorView"
              ? `${t("thePage")} ${name} ${t("successUnPublishPage")} ${t(
                  "fromSearchPlatform"
                )}`
              : `${t("theDocument")} ${name} ${t(
                  "successUnPublishDocument"
                )} ${t("fromSearchPlatform")}`,
            "success"
          );
        }
        setCurrentAction(null);
        if (page === "editorView") {
          getSingleImageByDocumentIdAndImageId(
            documentId,
            imageId,
            "afterPublishOrHidePageOnSebr"
          );
        }
        getFolderByDocumentId(documentId, "afterPublishOrHideDocOnSebr");
      })
      .catch(async (err) => {
        setCurrentAction(null);
        getSnackbar(
          page === "editorView"
            ? `${t("errorUnPublishPage")} ${name}, ${t("pleaseTryAgain")}`
            : `${t("errorUnPublishDocument")} ${name}, ${t("pleaseTryAgain")}`,
          "error"
        );
      });
  };

  return (
    <SebrContext.Provider
      value={{
        ...state,
        setCurrentAction,
        publishFile,
        unPublishFile,
      }}
    >
      {children}
    </SebrContext.Provider>
  );
};

export default SebrContext;
